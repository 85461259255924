import { default as displayq2Yq9eaPX1Meta } from "/app/pages/display.vue?macro=true";
import { default as _91slug_935xGnk5JrpsMeta } from "/app/pages/events/[slug].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91slug_93iP8xUx47HeMeta } from "/app/pages/orgs/[slug].vue?macro=true";
import { default as indexF331mkLKAPMeta } from "/app/pages/orgs/index.vue?macro=true";
import { default as orientationF77HeihXBPMeta } from "/app/pages/orientation.vue?macro=true";
import { default as _91id_93G8CPKk2GFFMeta } from "/app/pages/portal/event/[id].vue?macro=true";
import { default as create6fEH8kI0rlMeta } from "/app/pages/portal/event/create.vue?macro=true";
import { default as index4bnDMlRibmMeta } from "/app/pages/portal/event/index.vue?macro=true";
import { default as indexpSPtK4vy5gMeta } from "/app/pages/portal/index.vue?macro=true";
import { default as _91id_93k9TpSyzJv9Meta } from "/app/pages/portal/org/[id].vue?macro=true";
import { default as _91id_93ROGs7Dl9ycMeta } from "/app/pages/sso/event/[id].vue?macro=true";
import { default as createR83Qk5HC03Meta } from "/app/pages/sso/event/create.vue?macro=true";
import { default as indexcrfRHasxn6Meta } from "/app/pages/sso/event/index.vue?macro=true";
import { default as index59YwgloyDSMeta } from "/app/pages/sso/index.vue?macro=true";
import { default as _91id_93zMimKl2FDgMeta } from "/app/pages/sso/org/[id].vue?macro=true";
import { default as createSEFv9onrhIMeta } from "/app/pages/sso/org/create.vue?macro=true";
import { default as indexYE59H62UtPMeta } from "/app/pages/sso/org/index.vue?macro=true";
import { default as settingsJAiUaKENQuMeta } from "/app/pages/sso/settings.vue?macro=true";
export default [
  {
    name: displayq2Yq9eaPX1Meta?.name ?? "display",
    path: displayq2Yq9eaPX1Meta?.path ?? "/display",
    meta: displayq2Yq9eaPX1Meta || {},
    alias: displayq2Yq9eaPX1Meta?.alias || [],
    redirect: displayq2Yq9eaPX1Meta?.redirect,
    component: () => import("/app/pages/display.vue").then(m => m.default || m)
  },
  {
    name: _91slug_935xGnk5JrpsMeta?.name ?? "events-slug",
    path: _91slug_935xGnk5JrpsMeta?.path ?? "/events/:slug()",
    meta: _91slug_935xGnk5JrpsMeta || {},
    alias: _91slug_935xGnk5JrpsMeta?.alias || [],
    redirect: _91slug_935xGnk5JrpsMeta?.redirect,
    component: () => import("/app/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWgIHVzns1AMeta?.name ?? "events",
    path: indexWgIHVzns1AMeta?.path ?? "/events",
    meta: indexWgIHVzns1AMeta || {},
    alias: indexWgIHVzns1AMeta?.alias || [],
    redirect: indexWgIHVzns1AMeta?.redirect,
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iP8xUx47HeMeta?.name ?? "orgs-slug",
    path: _91slug_93iP8xUx47HeMeta?.path ?? "/orgs/:slug()",
    meta: _91slug_93iP8xUx47HeMeta || {},
    alias: _91slug_93iP8xUx47HeMeta?.alias || [],
    redirect: _91slug_93iP8xUx47HeMeta?.redirect,
    component: () => import("/app/pages/orgs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexF331mkLKAPMeta?.name ?? "orgs",
    path: indexF331mkLKAPMeta?.path ?? "/orgs",
    meta: indexF331mkLKAPMeta || {},
    alias: indexF331mkLKAPMeta?.alias || [],
    redirect: indexF331mkLKAPMeta?.redirect,
    component: () => import("/app/pages/orgs/index.vue").then(m => m.default || m)
  },
  {
    name: orientationF77HeihXBPMeta?.name ?? "orientation",
    path: orientationF77HeihXBPMeta?.path ?? "/orientation",
    meta: orientationF77HeihXBPMeta || {},
    alias: orientationF77HeihXBPMeta?.alias || [],
    redirect: orientationF77HeihXBPMeta?.redirect,
    component: () => import("/app/pages/orientation.vue").then(m => m.default || m)
  },
  {
    name: _91id_93G8CPKk2GFFMeta?.name ?? "portal-event-id",
    path: _91id_93G8CPKk2GFFMeta?.path ?? "/portal/event/:id()",
    meta: _91id_93G8CPKk2GFFMeta || {},
    alias: _91id_93G8CPKk2GFFMeta?.alias || [],
    redirect: _91id_93G8CPKk2GFFMeta?.redirect,
    component: () => import("/app/pages/portal/event/[id].vue").then(m => m.default || m)
  },
  {
    name: create6fEH8kI0rlMeta?.name ?? "portal-event-create",
    path: create6fEH8kI0rlMeta?.path ?? "/portal/event/create",
    meta: create6fEH8kI0rlMeta || {},
    alias: create6fEH8kI0rlMeta?.alias || [],
    redirect: create6fEH8kI0rlMeta?.redirect,
    component: () => import("/app/pages/portal/event/create.vue").then(m => m.default || m)
  },
  {
    name: index4bnDMlRibmMeta?.name ?? "portal-event",
    path: index4bnDMlRibmMeta?.path ?? "/portal/event",
    meta: index4bnDMlRibmMeta || {},
    alias: index4bnDMlRibmMeta?.alias || [],
    redirect: index4bnDMlRibmMeta?.redirect,
    component: () => import("/app/pages/portal/event/index.vue").then(m => m.default || m)
  },
  {
    name: indexpSPtK4vy5gMeta?.name ?? "portal",
    path: indexpSPtK4vy5gMeta?.path ?? "/portal",
    meta: indexpSPtK4vy5gMeta || {},
    alias: indexpSPtK4vy5gMeta?.alias || [],
    redirect: indexpSPtK4vy5gMeta?.redirect,
    component: () => import("/app/pages/portal/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93k9TpSyzJv9Meta?.name ?? "portal-org-id",
    path: _91id_93k9TpSyzJv9Meta?.path ?? "/portal/org/:id()",
    meta: _91id_93k9TpSyzJv9Meta || {},
    alias: _91id_93k9TpSyzJv9Meta?.alias || [],
    redirect: _91id_93k9TpSyzJv9Meta?.redirect,
    component: () => import("/app/pages/portal/org/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ROGs7Dl9ycMeta?.name ?? "sso-event-id",
    path: _91id_93ROGs7Dl9ycMeta?.path ?? "/sso/event/:id()",
    meta: _91id_93ROGs7Dl9ycMeta || {},
    alias: _91id_93ROGs7Dl9ycMeta?.alias || [],
    redirect: _91id_93ROGs7Dl9ycMeta?.redirect,
    component: () => import("/app/pages/sso/event/[id].vue").then(m => m.default || m)
  },
  {
    name: createR83Qk5HC03Meta?.name ?? "sso-event-create",
    path: createR83Qk5HC03Meta?.path ?? "/sso/event/create",
    meta: createR83Qk5HC03Meta || {},
    alias: createR83Qk5HC03Meta?.alias || [],
    redirect: createR83Qk5HC03Meta?.redirect,
    component: () => import("/app/pages/sso/event/create.vue").then(m => m.default || m)
  },
  {
    name: indexcrfRHasxn6Meta?.name ?? "sso-event",
    path: indexcrfRHasxn6Meta?.path ?? "/sso/event",
    meta: indexcrfRHasxn6Meta || {},
    alias: indexcrfRHasxn6Meta?.alias || [],
    redirect: indexcrfRHasxn6Meta?.redirect,
    component: () => import("/app/pages/sso/event/index.vue").then(m => m.default || m)
  },
  {
    name: index59YwgloyDSMeta?.name ?? "sso",
    path: index59YwgloyDSMeta?.path ?? "/sso",
    meta: index59YwgloyDSMeta || {},
    alias: index59YwgloyDSMeta?.alias || [],
    redirect: index59YwgloyDSMeta?.redirect,
    component: () => import("/app/pages/sso/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zMimKl2FDgMeta?.name ?? "sso-org-id",
    path: _91id_93zMimKl2FDgMeta?.path ?? "/sso/org/:id()",
    meta: _91id_93zMimKl2FDgMeta || {},
    alias: _91id_93zMimKl2FDgMeta?.alias || [],
    redirect: _91id_93zMimKl2FDgMeta?.redirect,
    component: () => import("/app/pages/sso/org/[id].vue").then(m => m.default || m)
  },
  {
    name: createSEFv9onrhIMeta?.name ?? "sso-org-create",
    path: createSEFv9onrhIMeta?.path ?? "/sso/org/create",
    meta: createSEFv9onrhIMeta || {},
    alias: createSEFv9onrhIMeta?.alias || [],
    redirect: createSEFv9onrhIMeta?.redirect,
    component: () => import("/app/pages/sso/org/create.vue").then(m => m.default || m)
  },
  {
    name: indexYE59H62UtPMeta?.name ?? "sso-org",
    path: indexYE59H62UtPMeta?.path ?? "/sso/org",
    meta: indexYE59H62UtPMeta || {},
    alias: indexYE59H62UtPMeta?.alias || [],
    redirect: indexYE59H62UtPMeta?.redirect,
    component: () => import("/app/pages/sso/org/index.vue").then(m => m.default || m)
  },
  {
    name: settingsJAiUaKENQuMeta?.name ?? "sso-settings",
    path: settingsJAiUaKENQuMeta?.path ?? "/sso/settings",
    meta: settingsJAiUaKENQuMeta || {},
    alias: settingsJAiUaKENQuMeta?.alias || [],
    redirect: settingsJAiUaKENQuMeta?.redirect,
    component: () => import("/app/pages/sso/settings.vue").then(m => m.default || m)
  }
]